import {
  Box,
  Flex,
  HStack,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { Spacer } from "@chakra-ui/layout";
import logo from "../images/favicon-32x32.png";

const Links = ["About", "Projects", "Contact"];

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    onClick={() =>{scroll(children)}}
  >
    {children}
  </Link>
);

const scroll = (a) => {
  let x = document.getElementById(a);
  x.scrollIntoView({ behavior: "smooth" });
  console.log(a)
};
export const Navbar = () => {
  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4} w={"100%"}>
        <HStack spacing={8} alignItems={"center"}>
          <Image
            borderRadius="full"
            boxSize="2.25rem"
            src={logo}
            alt="LS"
            p={1}
          />
          <Spacer></Spacer>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            ></MenuButton>
            <MenuList>
              <MenuItem>Link 1</MenuItem>
              <MenuItem>Link 2</MenuItem>
              <MenuDivider />
              <MenuItem>Link 3</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </>
  );
};
