import {
  Box,
  VStack,
  Flex,
  Divider,
  chakra,
  Grid,
  GridItem,
  Container,
} from "@chakra-ui/react";
export const About = () => {
  return (
    <Box as={Container} maxW="7xl" mt={14} p={4} id="About" >
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
        }}
        gap={4}
      >
        <GridItem colSpan={1}>
          <VStack spacing="20px">
            <chakra.h2
              fontSize="3xl"
              fontWeight="700"
              color={"blue.400"}
              as={"span"}
            >
              About Me
            </chakra.h2>
          </VStack>
        </GridItem>
        <GridItem>
          <Flex>
            <chakra.p>
              Hi, I'm Lakshay Sachdeva, born and brought up in New Delhi. I did
              my graduation in Mechanical and Automation Engineering from Guru
              Gobind Singh Indraprastha University, New Delhi.
              <br />
              <br />
              About my experience with computers, I did have C++ as my main
              subject in senior secondary and that was my first experience as
              coding and later I had C language in my second semester.
              <br />
              <br />
              While still in my graduation my interest grew more towards
              building websites, as I used to enjoy reading technical
              specifications and more about automobiles, and there wasn't any
              good resources that has all informaton about the autmobiles
              compiled at one place, so I wanted to build one, but there was a
              lot of knowledge gap.
              <br />
              <br />
              After pandemic in 2020 I got an opportunity to learn about web
              development, and now I am able to build full stack websites using
              MERN Stack, and now I am dedicated to develop interactive and user
              friendly websites. I always love to learn new things.
            </chakra.p>
          </Flex>
        </GridItem>
      </Grid>
      <Divider mt={12} mb={12} />
    </Box>
  );
};
