import {
  Container,
  chakra,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Link,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { AiFillGithub } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { BiLink } from "react-icons/bi";

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export const ProjectCard = () => {
  return (
    <>
      <chakra.h2
        fontSize="3xl"
        fontWeight="700"
        color={"blue.400"}
        as={"span"}
        p={8}
        id="Projects"
      >
        My Projects
      </chakra.h2>
      <Container maxW={"5xl"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading>Tanishq - Clone</Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              Tanishq is an Indian jewellery brand and a division of Titan
              Company. Founded in 1994, Tanishq is headquartered in Bangalore,
              Karnataka, India.
            </Text>
            <Text color={"gray.500"} fontSize={"lg"}>
              Tech Stack : NodeJS | ExpressJS | MongoDB | EJS | Tailwind CSS
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <Link
                href="https://github.com/Lakshay-Sachdeva/tanishq_clone"
                target={"_blank"}
              >
                <Feature
                  icon={<Icon as={AiFillGithub} w={5} h={5} />}
                  text={"GitHub"}
                />
              </Link>
              <Link
                href="https://drive.google.com/file/d/18JbhK3lxtYbZk5Unoi2PEeTCtGKEi5oB/view?usp=sharing"
                target={"_blank"}
              >
                <Feature
                  icon={
                    <Icon
                      as={BsFillCameraVideoFill}
                      color={"blue.400"}
                      w={5}
                      h={5}
                    />
                  }
                  iconBg={useColorModeValue("blue.100", "blue.900")}
                  text={"Demo Video"}
                />
              </Link>
              <Link
                href="http://tanishq-clone.herokuapp.com/"
                target={"_blank"}
              >
                <Feature
                  icon={<Icon as={BiLink} color={"purple.500"} w={5} h={5} />}
                  iconBg={useColorModeValue("purple.100", "purple.900")}
                  text={"Live Site"}
                />
              </Link>
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={
                "https://camo.githubusercontent.com/56bac333b98b4ba8600a4cc78375cb3679fd5ec5c3e0a4820151926eca210ec7/68747470733a2f2f692e6962622e636f2f51396b436652772f686f6d65706167652e706e67"
              }
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
      <Container maxW={"5xl"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading>Zara - Clone</Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              Zara is a Spanish apparel retailer based in Spain. The company
              specialises in fast fashion, and products include clothing,
              accessories, shoes, swimwear, beauty, and perfumes.
            </Text>
            <Text color={"gray.500"} fontSize={"lg"}>
              Tech Stack : HTML | CSS | Javascript
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <Link
                href="https://github.com/Lakshay-Sachdeva/zara"
                target={"_blank"}
              >
                <Feature
                  icon={<Icon as={AiFillGithub} w={5} h={5} />}
                  text={"GitHub"}
                />
              </Link>
              <Link
                href="https://drive.google.com/file/d/1J6O-BhUKqqI1zlVTpjwaF4AAhrfzJ7E_/view?usp=sharing"
                target={"_blank"}
              >
                <Feature
                  icon={
                    <Icon
                      as={BsFillCameraVideoFill}
                      color={"blue.400"}
                      w={5}
                      h={5}
                    />
                  }
                  iconBg={useColorModeValue("blue.100", "blue.900")}
                  text={"Demo Video"}
                />
              </Link>
              <Link href="https://zaraclone.netlify.app/" target={"_blank"}>
                <Feature
                  icon={<Icon as={BiLink} color={"purple.500"} w={5} h={5} />}
                  iconBg={useColorModeValue("purple.100", "purple.900")}
                  text={"Live Site"}
                />
              </Link>
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={
                "https://camo.githubusercontent.com/f84c5dae37d677419f6c91a6e5266fd549810dbff1575373344aebb335db5b5c/68747470733a2f2f692e6962622e636f2f4a326b57524c6a2f7a6172612d686f6d65706167652e706e67"
              }
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
      {/* <Container maxW={"5xl"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading>Flipkart - Clone</Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              Flipkart is India's one of the leading e-commerce site, where
              anyone and everyone can buy anything and everything.
            </Text>
            <Text color={"gray.500"} fontSize={"lg"}>
              Tech Stack : HTML | CSS | Javascript
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <Link
                href="https://github.com/Lakshay-Sachdeva/unit-2_team16_flipkart"
                target={"_blank"}
              >
                <Feature
                  icon={<Icon as={AiFillGithub} w={5} h={5} />}
                  text={"GitHub"}
                />
              </Link>
              <Link
                href="https://drive.google.com/file/d/16veW4cjtII1T5REJL7-NwnWBbdeRb5un/view?usp=sharing"
                target={"_blank"}
              >
                <Feature
                  icon={
                    <Icon
                      as={BsFillCameraVideoFill}
                      color={"blue.400"}
                      w={5}
                      h={5}
                    />
                  }
                  iconBg={useColorModeValue("blue.100", "blue.900")}
                  text={"Demo Video"}
                />
              </Link>
              <Link href="https://flipclone.netlify.app/" target={"_blank"}>
                <Feature
                  icon={<Icon as={BiLink} color={"purple.500"} w={5} h={5} />}
                  iconBg={useColorModeValue("purple.100", "purple.900")}
                  text={"Live Site"}
                />
              </Link>
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={
                "https://camo.githubusercontent.com/9550a7ad909d546b8fb900b4a6d4721cf47d6fb57ec49748813028f44d9b1ee5/68747470733a2f2f692e6962622e636f2f335742716343352f666c69706b6172742d686f6d65706167652e706e67"
              }
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container> */}
      <Container maxW={"5xl"} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading>Star wars Search Engine</Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              Star Wars Search Engine is the mini app designed to search details
              of character who are originated and belongs to star wars universe
            </Text>
            <Text color={"gray.500"} fontSize={"lg"}>
              Tech Stack : HTML | CSS | Javascript
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <Link
                href="https://github.com/Lakshay-Sachdeva/starwars"
                target={"_blank"}
              >
                <Feature
                  icon={<Icon as={AiFillGithub} w={5} h={5} />}
                  text={"GitHub"}
                />
              </Link>
              <Link
                href="https://drive.google.com/file/d/1VKo_V7RnYBUdYA6Hx_OcCAZtili51iJZ/view?usp=sharing"
                target={"_blank"}
              >
                <Feature
                  icon={
                    <Icon
                      as={BsFillCameraVideoFill}
                      color={"blue.400"}
                      w={5}
                      h={5}
                    />
                  }
                  iconBg={useColorModeValue("blue.100", "blue.900")}
                  text={"Demo Video"}
                />
              </Link>
              <Link
                href="https://lakshay-star-war.netlify.app/"
                target={"_blank"}
              >
                <Feature
                  icon={<Icon as={BiLink} color={"purple.500"} w={5} h={5} />}
                  iconBg={useColorModeValue("purple.100", "purple.900")}
                  text={"Live Site"}
                />
              </Link>
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={
                "https://camo.githubusercontent.com/5c06fd5c160a6b346d52b52868b7d6a4957cf97c8620f70fdb3abf1ca74f7468/68747470733a2f2f692e6962622e636f2f4c764b537044352f73746172776172732e706e67"
              }
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
    </>
  );
};
