// import logo from "./logo.svg";
import "./App.css";
import { Navbar } from "./components/Navbar.jsx";
import { Intro } from "./components/Intro.jsx";
import { About } from "./components/About.jsx";
import { TechStack } from "./components/TechStack.jsx";
import { VStack } from "@chakra-ui/layout";
import { ProjectCard } from "./components/ProjectCard.jsx";
import { Contact } from "./components/Contact.jsx";

function App() {
  return (
    <VStack>
      <Navbar />
      <Intro />
      <About id="About" />
      <TechStack />
      <ProjectCard id="Projects" />
      <Contact />
    </VStack>
  );
}

export default App;
