import React from "react";
import Icon from "@chakra-ui/icon";
import { Heading, HStack } from "@chakra-ui/layout";
import { useColorModeValue, chakra, Divider } from "@chakra-ui/react";
import { ReactComponent as Html } from "../images/html-5.svg";
import { ReactComponent as Css } from "../images/css-3.svg";
import { ReactComponent as JS } from "../images/javascript.svg";
import { ReactComponent as ReactJS } from "../images/react.svg";
import { ReactComponent as Redux } from "../images/redux.svg";
import { ReactComponent as NodeJS } from "../images/nodejs-icon.svg";
import { ReactComponent as ExpressJs } from "../images/express.svg";
import { ReactComponent as Mongo } from "../images/mongodb.svg";
import { ReactComponent as Postman } from "../images/postman-icon.svg";
import { ReactComponent as Vercel } from "../images/vercel-icon.svg";
import { ReactComponent as Netlify } from "../images/netlify.svg";
import { ReactComponent as Heroku } from "../images/heroku-icon.svg";
import { ReactComponent as Git } from "../images/git-icon.svg";
import { ReactComponent as Github } from "../images/github-icon.svg";
import { ReactComponent as Redis } from "../images/redis.svg";
import { ReactComponent as npm } from "../images/npm.svg";

export const TechStack = () => {
  const frontend = [
    [Html, "HTML 5"],
    [Css, "CSS 3"],
    [JS, "Javascript"],
    [ReactJS, "ReactJS"],
    [Redux, "Redux"],
  ];
  const backend = [
    [NodeJS, "NodeJS"],
    [ExpressJs, "ExpressJS"],
    [Mongo, "MongoDB"],
    [Redis, "Redis"],
  ];

  const tools = [
    [Postman, "Postman"],
    [Vercel, "Vercel"],
    [Netlify, "Netlify"],
    [Heroku, "Heroku"],
    [npm, "npm"],
  ];
  const versionControl = [
    [Git, "Git"],
    [Github, "GitHub"],
  ];
  return (
    <>
      <chakra.h2 fontSize="3xl" fontWeight="700" color={"blue.400"} as={"span"}>
        Tech Stack
      </chakra.h2>
      <br />
      <Heading
        textTransform={"uppercase"}
        color={"blue.400"}
        fontSize={"lg"}
        bg={useColorModeValue("blue.50", "blue.900")}
        p={2}
        rounded={"md"}
      >
        Frontend
      </Heading>
      <br />
      <HStack spacing="24">
        {frontend.map((e) => (
          <div>
            <Icon as={e[0]} boxSize="50" />
            <br />
            <div>{e[1]}</div>
          </div>
        ))}
      </HStack>
      <br />
      <br />
      <Heading
        textTransform={"uppercase"}
        color={"blue.400"}
        fontSize={"lg"}
        bg={useColorModeValue("blue.50", "blue.900")}
        p={2}
        rounded={"md"}
      >
        Backend
      </Heading>
      <br />
      <HStack spacing="24">
        {backend.map((e) => (
          <div>
            <Icon as={e[0]} boxSize="50" />
            <br />
            <div>{e[1]}</div>
          </div>
        ))}
      </HStack>
      <br />
      <br />
      <Heading
        textTransform={"uppercase"}
        color={"blue.400"}
        fontSize={"lg"}
        bg={useColorModeValue("blue.50", "blue.900")}
        p={2}
        rounded={"md"}
      >
        Tools
      </Heading>
      <br />
      <HStack spacing="24">
        {tools.map((e) => (
          <div>
            <Icon as={e[0]} boxSize="50" />
            <br />
            <div>{e[1]}</div>
          </div>
        ))}
      </HStack>
      <br />
      <br />
      <Heading
        textTransform={"uppercase"}
        color={"blue.400"}
        fontSize={"lg"}
        bg={useColorModeValue("blue.50", "blue.900")}
        p={2}
        rounded={"md"}
      >
        Version Control
      </Heading>
      <br />
      <HStack spacing="24" mt={4}>
        {versionControl.map((e) => (
          <div>
            <Icon as={e[0]} boxSize="50" />
            <br />
            <div>{e[1]}</div>
          </div>
        ))}
      </HStack>
      <br />
      <br />
      <Divider mt={12} mb={12} />
    </>
  );
};
